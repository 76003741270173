<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
/deep/ .info_pay{
  background: #000;
}
</style>
